<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <div class="flex justify-between items-center" v-if="this.showEvents">
        <h1
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl"
        >
          Favourite Venues
        </h1>
        <div class="relative">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search by Venue Name or Location..."
            class="border p-2 w-full md:w-64 lg:w-96"
            @input="resetPagination"
          />
          <span
            v-if="searchQuery"
            class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            @click="clearSearch"
          >
            <i class="fa fa-times text-gray-500"></i>
          </span>
        </div>
        <div class="flex items-center space-x-4">
          <button
            type="button"
            :disabled="this.showSync"
            class="bg-green-300 rounded-full font-semibold py-2 mt-4 mb-4 mr-4 text-center px-4"
            @click="sync"
          >
            {{ this.showSync ? "Syncing..." : "Sync from favourites" }}
          </button>
        </div>
      </div>

      <div class="flex justify-between items-center" v-if="!this.showEvents">
        <h1
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl"
        >
          {{ this.showName }}
        </h1>
        <div class="flex items-center space-x-4">
          <button
            type="button"
            class="bg-green-300 rounded-full font-semibold py-2 mt-4 mb-4 mr-4 text-center px-4"
            @click="showCal('')"
          >
            Close
          </button>
        </div>
      </div>

      <div class="mb-4" v-if="this.showEvents">
        <!-- component -->
        <div id="" class="">
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Venue Name
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Venue Location
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Upcoming Events
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Newly Events
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Updated At
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              <!-- <tr
                v-for="(item, i) in paginatedItems"
                :key="i"
                class="w-full bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              > -->
              <!-- ... existing code ... -->
              <!-- </tr> -->
              <tr
                v-if="!items"
                class="w-full bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="5"
                >
                  Please wait...
                </td>
              </tr>
              <tr
                v-for="(item, i) in paginatedItems"
                :key="i"
                class="w-full bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                >
                  {{ item.venue_name }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                >
                  {{ item.vlocation }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.upcoming }}
                </td>

                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <span v-if="item.newly != 0" class="badge">{{
                    item.newly
                  }}</span>
                  <span v-if="item.newly == 0">{{ item.newly }}</span>
                </td>

                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.vdate }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <div v-if="loading">
                    Processing...
                  </div>
                  <div
                    v-if="!loading"
                    class="flex justify-between items-center"
                  >
                    <form method="post" @submit.prevent="viewVenue(item)">
                      <button
                        class="focus:outline-none text-blue-600 text-sm hover:underline"
                        type=""
                      >
                        View
                      </button>
                    </form>
                    &nbsp;
                    <form method="post" @submit.prevent="updateVenue(item.id)">
                      <button
                        class="focus:outline-none text-blue-600 text-sm hover:underline"
                        type=""
                      >
                        Delete
                      </button>
                    </form>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination Controls -->
        <div class="flex items-center space-x-4 mt-4">
          <button
            @click="prevPage"
            :disabled="currentPage === 1"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Previous
          </button>
          <span class="text-lg">{{ currentPage }}</span>
          <button
            @click="nextPage"
            :disabled="currentPage === Math.ceil(items.length / itemsPerPage)"
            class="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Next
          </button>
          <div class="mt-2">
            Pages :
            {{ Math.ceil(items.length / itemsPerPage) }}, Total :
            {{ items.length }}
          </div>
        </div>
      </div>

      <div
        v-if="!this.showEvents"
        class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <FullCalendar
          v-if="!this.showEvents"
          :key="this.calendarKey"
          :options="calendarOptions"
          :ref="this.calendarRef"
        />
      </div>
    </div>
  </main>
</template>

<script>
// import Pagination from "vue-pagination-2";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridWeek from "@fullcalendar/timegrid";
import multiMonth from "@fullcalendar/multimonth";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
// import moment from "moment-timezone";
import "viewerjs/dist/viewer.css";
import { BPopover } from "bootstrap-vue";

export default {
  name: "VCalendar",
  components: {
    FullCalendar
  },
  data() {
    return {
      items: [],
      events: [],
      calendarKey: 1,
      calendarRef: "venCalendar",
      loading: false,
      showEvents: true,
      showSync: false,
      currentPage: 1,
      itemsPerPage: 15,
      searchQuery: "",
      showName: ""
    };
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredItems.slice(startIndex, endIndex);
    },
    filteredItems() {
      // Filter items based on the search query
      return this.items.filter(
        item =>
          item.venue_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.vlocation.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    calendarOptions() {
      return {
        plugins: [
          momentTimezonePlugin,
          dayGridPlugin,
          timeGridWeek,
          multiMonth
        ],
        timeZone: "UTC",
        headerToolbar: {
          left: "dayGridMonth,timeGridWeek,timeGridDay",
          center: "title",
          right: "today prev,next"
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventDidMount: function(info) {
          new BPopover({
            propsData: {
              title: info.event.extendedProps.title,
              content: info.event.extendedProps.description,
              triggers: "hover",
              placement: "top",
              target: info.el
            }
          }).$mount();
        },
        // eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: this.events
        // eventRender: this.customizeEventRendering
        // eventRender: function(event, element) {
        //   console.log("cevent");
        //   console.log(event);
        //   if (event.type == "planted") {
        //     element[0].cells[1].children[0].style.background = "#08b394"; //works!
        //   } else {
        //     element[0].cells[1].children[0].style.background = "#2a7568"; //works!
        //   }
        // }
      };
    }
  },
  created() {
    this.get();
  },
  methods: {
    async sync() {
      this.loading = true;
      this.showSync = true;
      await this.$axios.get("https://varilytics.com/spaceseat/event_crowl.php");
      this.loading = false;
      this.showSync = false;
      this.get();
    },
    showCal(vname) {
      this.showName = vname;
      this.showEvents = !this.showEvents;
    },
    async get() {
      try {
        const res = await this.$axios.get(`https://varilytics.com/api/venues`);
        this.items = res.data.data;
        for (let i = 0; i < this.items.length; i++) {
          this.$set(this.items[i], "count", 0);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async viewVenue(item) {
      // console.log(item);
      this.showCal(item.venue_name);

      try {
        const response = await this.$axios.get(
          `https://varilytics.com/api/venevents?vid=` + item.venue_id
        );
        // console.log("response.data.data");
        // console.log(response.data.data);
        var thi = [];
        // console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          let title = this.addStringBeforeSpecific(
            response.data.data[i].event_name,
            response.data.data[i].sale_type
          );
          thi.push({
            title: title,
            description: title,
            start:
              response.data.data[i].edate != ""
                ? response.data.data[i].edate
                : response.data.data[i].date.replaceAll("/", "-"),
            id: response.data.data[i].fav_id,
            groupId: response.data.data[i].fav_id,
            data: response.data.data[i].events,
            // timeZone: response.data.data[i].timezone,
            color: response.data.data[i].fav_type,
            textColor: response.data.data[i].fav_type == "yellow" ? "black" : "white"
          });
        }
        // thi.push({
        //   title:
        //     "Test 11  Test 12 Test 13 Test 14 Test 15 Test 16 Test 17 Test 18 Test 19 Test 20",
        //   start: "2023-11-26T09:00:00Z"
        // });

        // console.log(thi);
        this.events = this.filterUniqueArray(thi);
        this.calendarKey = this.events.length;
      } catch (e) {
        console.log(e);
      }
      console.log("this.events");
      console.log(this.events);
    },
    async updateVenue(id) {
      try {
        this.loading = true;
        await this.$axios.get("https://varilytics.com/api/vupdate?vid=" + id);
        this.loading = false;
        this.showEvents = false;
        this.get();
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < Math.ceil(this.items.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    },
    resetPagination() {
      this.currentPage = 1;
    },
    clearSearch() {
      this.searchQuery = "";
    },
    filterUniqueArray(arr) {
      const uniqueItems = []; // Create a new array for the unique objects
      const seen = new Set(); // Create a Set to store unique combinations of title and type

      for (const item of arr) {
        const key = `${item.title}-${item.start}`; // Create a unique key based on title and type

        if (!seen.has(key)) {
          // If the key is not in the Set, it's unique
          seen.add(key);
          uniqueItems.push(item);
        }
      }

      return uniqueItems;
    },
    addStringBeforeSpecific(originalString, newString) {
      // Escape special characters in the specific string
      let originalText = originalString;
      let comingText = newString;

      // Split the original text into an array of substrings
      let originalArray = originalText.split(", ");

      // Insert the coming text before the price at the appropriate position
      originalArray.splice(4, 0, comingText);

      // Join the array back into a string
      let resultText = originalArray.join(", ");

      return resultText;
    }
  }
};
</script>
